export const contentDataConstants = {
	REQUEST: 'TYPES_REQUEST',
	SUCCESS: 'TYPES_SUCCESS',
	FAILURE: 'TYPES_FAILURE',

	REQUEST_THEMES: 'THEMES_REQUEST',
	SUCCESS_THEMES: 'THEMES_SUCCESS',
	FAILURE_THEMES: 'THEMES_FAILURE',

	UPDATE_TYPE_REQUEST: 'UPDATE_CONTENT_TYPE_REQUEST',
	UPDATE_TYPE_SUCCESS: 'UPDATE_CONTENT_TYPE_SUCCESS',
	UPDATE_TYPE_FAILURE: 'UPDATE_CONTENT_TYPE_FAILURE'
}
