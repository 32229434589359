import { userActions, alertActions } from './_actions'
export function handleResponse(response) {
	return response.text().then((text) => {
		const data = text && JSON.parse(text)
		if (!response.ok) {
			if (response.status === 401) {
				// auto logout if 401 response returned from api
				userActions.logout()
			}
			if (response.status === 403) {
				alertActions.error('Sua sessão expirou, faça login novamente!')
				userActions.logout()
			}

			const error = (data && data.message) || response.statusText
			return Promise.reject(error)
		}

		return data
	})
}
