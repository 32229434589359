import { filteredConstants } from '../_constants'
const INITIAL_STATE = {
	filteredrecent: false,
	filteredseries: false,
	filteredName: '',
	filteredContent: []
}

const boolFiltered = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case filteredConstants.FILTERRECENT:
			return { ...state, filteredrecent: action.payload }
		case filteredConstants.FILTERSERIES:
			return { ...state, filteredseries: action.payload }
		case filteredConstants.FILTERNAME:
			return { ...state, filteredName: action.payload }
		case filteredConstants.FILTERRECENTCONT:
			return { ...state, filteredContent: action.payload }
		default:
			return state
	}
}

export default boolFiltered
