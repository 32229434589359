import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import translationES from './public/locales/es/translation.json'
import translationPT from './public/locales/pt-BR/translation.json'

const resources = {
	pt: {
		translation: translationPT
	},
	es: {
		translation: translationES
	}
}

i18n.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug: true,
		fallbackLng: 'pt-BR',
		resources,
		detection: {
			order: ['htmlTag', 'navigator']
		}
	})

export default i18n
