import { alertConstants } from '../_constants'

export function alert(state = {}, action) {
	switch (action.type) {
		case alertConstants.SUCCESS:
			return {
				type: 'green',
				message: action.message,
				component: action.component,
				location: state.location
			}
		case alertConstants.ERROR:
			return {
				type: 'red',
				message: action.message,
				component: action.component,
				location: state.location
			}
		case alertConstants.CLEAR:
			return {
				location: state.location
			}
		case alertConstants.LOCATION:
			return {
				location: action.location
			}
		default:
			return state
	}
}
