import React from 'react'
import { Modal, Icon as SemanticIcon, Button, Input } from 'semantic-ui-react'

const host = process.env.REACT_APP_HOSTNAME
function ModalShare({ open, handleOpen, url }) {
	const shareUrl = `${host}/${url}`

	function handleCopy() {
		navigator.clipboard.writeText(shareUrl)
	}
	return (
		<Modal
			size="tiny"
			closeIcon
			onClose={() => handleOpen(false)}
			onOpen={() => handleOpen(true)}
			open={open}
		>
			<Modal.Header>Compartilhar</Modal.Header>
			<Modal.Content>
				<Modal.Description>
					<Button
						color="facebook"
						as="a"
						target="blank"
						href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
					>
						<SemanticIcon name="facebook" /> Facebook
					</Button>
					<Button
						color="twitter"
						as="a"
						target="blank"
						href={`https://twitter.com/intent/tweet?text=Proteja - Áreas protegidas do Brasil: ${shareUrl}`}
					>
						<SemanticIcon name="twitter" /> Twitter
					</Button>
					<Button
						color="black"
						href={`mailto:?subject=Proteja - Áreas protegidas do Brasil&body=${shareUrl}`}
					>
						<SemanticIcon name="envelope" /> E-mail
					</Button>
					<div style={{ marginTop: 10 }}>
						<Input
							fluid
							action={{
								icon: 'copy',
								content: 'Copiar',
								onClick: () => handleCopy()
							}}
							readOnly
							defaultValue={shareUrl}
						/>
					</div>
				</Modal.Description>
			</Modal.Content>
		</Modal>
	)
}

export default ModalShare
