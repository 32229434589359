import { locationConstants } from '../_constants'

export function states(state = { states: [] }, action) {
	switch (action.type) {
		case locationConstants.STATE_REQUEST:
			return {
				fetchingStates: true,
				...state
			}
		case locationConstants.STATE_SUCCESS: {
			return {
				states: action.rows
			}
		}
		case locationConstants.STATE_FAILURE:
			return {}
		default:
			return state
	}
}
