import { handleResponse } from '../handle-response'

const config = {
	apiUrl: process.env.REACT_APP_API
}

export const contactService = {
	sendContactForm
}

function sendContactForm(params) {
	const requestOptions = {
		method: 'POST',
		body: JSON.stringify(params),
		headers: { 'Content-Type': 'application/json' }
	}

	return fetch(`${config.apiUrl}/contact-form/send`, requestOptions).then(
		handleResponse
	)
}
