import { contentDataConstants } from '../_constants'

export function themes(state = { themes: [] }, action) {
	switch (action.type) {
		case contentDataConstants.REQUEST_THEMES:
			return {
				loading: true,
				...state
			}
		case contentDataConstants.SUCCESS_THEMES:
			return {
				themes: action.rows
			}
		case contentDataConstants.ERROR_THEMES:
			return {}
		default:
			return state
	}
}
