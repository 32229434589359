import { locationConstants } from '../_constants'

export function cities(state = { cities: [] }, action) {
	switch (action.type) {
		case locationConstants.CITY_REQUEST:
			return {
				fetchingCities: true,
				...state
			}
		case locationConstants.CITY_SUCCESS: {
			return {
				cities: action.rows
			}
		}
		case locationConstants.CITY_FAILURE:
			return {
				cities: []
			}
		default:
			return state
	}
}
