import { alertConstants } from '../_constants'

export const alertActions = {
	success,
	error,
	clear,
	setLocation
}

function success(message, component) {
	return { type: alertConstants.SUCCESS, message, component }
}

function error(message, component) {
	return { type: alertConstants.ERROR, message, component }
}

function clear() {
	return { type: alertConstants.CLEAR }
}

function setLocation(location) {
	return { type: alertConstants.LOCATION, location }
}
