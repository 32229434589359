import { contentDataConstants } from '../_constants'

export function types(state = { types: [] }, action) {
	switch (action.type) {
		case contentDataConstants.REQUEST:
			return {
				loading: true,
				...state
			}
		case contentDataConstants.SUCCESS:
			return {
				types: action.rows
			}
		case contentDataConstants.UPDATE_TYPE_REQUEST:
		case contentDataConstants.UPDATE_TYPE_SUCCESS:
		case contentDataConstants.UPDATE_TYPE_FAILURE:
		case contentDataConstants.ERROR:
		default:
			return state
	}
}
